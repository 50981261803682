<template>
  <div class="property">
    <card :introduction="pageContent" :imageList="pagePictures" />
  </div>
</template>

<script>
import card from "@/components/system/card.vue";
import { queryPageManageList } from "@/api/api.js";
export default {
  name: "property",
  components: {
    card,
  },
  data() {
    return {
      pagePictures: [], //图片
      pageContent: "", //文本内容
    };
  },
  created() {
    queryPageManageList({ pageName: "物业服务" }).then((res) => {
      if (res.data.rows[0]) {
        this.pagePictures = res.data.rows[0].pagePicture.split(",");
        this.pageContent = res.data.rows[0].pageContent;
      }
    });
  },
};
</script>
